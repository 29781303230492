import request from '@/utils/request.js'

import { urlPath } from './apiBaseInfo'

// 查询接口列表
export const queryApiEnvList = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'apiEnv',
    params: {
      ...data
    }
  })
}

// 查询全部环境信息
export const queryEnvAll = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'apiEnv',
    params: {
      ...data
    }
  })
}

// 添加环境信息
export const addApiEnv = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'apiEnv',
    data
  })
}

// 编辑环境信息
export const editApiEnv = (data) => {
  return request({
    method: 'PUT',
    url: urlPath + 'apiEnv',
    data
  })
}

// 删除用例
export const deleteApiEnv = (data) => {
  return request({
    method: 'DELETE',
    url: urlPath + 'apiEnv',
    data
  })
}
