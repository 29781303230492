<template>
  <div class="apiEnv">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        环境管理
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header" class="clearfix" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <el-button type="primary" @click="handleAdd">新增环境</el-button>
      </div>
      <!-- 当前环境信息展示 -->
      <el-table
        :data="tableDataEnv"
        style="width: 100%">
        <el-table-column
          prop="id"
          label="环境编号"
          width="80">
        </el-table-column>
        <el-table-column
          prop="env_name"
          label="环境名称">
        </el-table-column>
        <el-table-column
          prop="protocal"
          label="请求协议">
        </el-table-column>
        <el-table-column
          prop="api_host"
          label="请求域名">
        </el-table-column>
        <el-table-column
          prop="project_id"
          label="所属项目">
        </el-table-column>
        <el-table-column
          prop="description"
          label="环境描述">
        </el-table-column>
        <el-table-column
          prop="status"
          label="环境状态">
        </el-table-column>
        <el-table-column
          prop="created_time"
          label="创建时间">
        </el-table-column>
        <el-table-column
          label="操作"
          width="150">
          <template slot-scope="scope">
            <el-button round type="warning" @click.stop="handleEdit(scope.row)">编辑</el-button>
            <el-button round type="danger" @click.stop="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-card>

    <!-- 测试环境分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[5, 10, 30, 50]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total_count">
    </el-pagination>

    <el-dialog
      :title="isAdd?'新增接口':'编辑接口'"
      :visible.sync="dialogVisibleEnv"
      width="50%"
      @close="closeDialogHandle">
      <el-form :model="ruleFormEnv" :rules="apiEnvFormRules" ref="ruleFormEnvRef" label-width="120px" class="demo-ruleForm">
        <el-form-item v-if="!isAdd" label="环境ID">
          <el-input v-model="ruleFormEnv.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="环境名称" prop="env_name">
          <el-input v-model="ruleFormEnv.env_name"></el-input>
        </el-form-item>
        <el-form-item label="请求协议" prop="env_name">
          <!-- <el-input v-model="ruleFormEnv.protocal"></el-input> -->
          <el-select v-model="ruleFormEnv.protocal" filterable placeholder="请选择">
            <el-option
              v-for="item in optionsProtocal"
              :key="item.id"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="环境域名" prop="env_name">
          <el-input v-model="ruleFormEnv.api_host" placeholder="www.baidu.com"></el-input>
        </el-form-item>
        <el-form-item label="所属项目" prop="env_name">
          <!-- <el-input v-model="ruleFormEnv.project_id"></el-input> -->
          <el-select v-model="ruleFormEnv.project_id" filterable placeholder="请选择">
            <el-option
              v-for="item in optionsProject"
              :key="item.id"
              :label="item.project_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="环境状态" prop="delivery">
          <el-switch v-model="ruleFormEnv.status" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="环境描述" prop="description">
          <el-input type="textarea" v-model="ruleFormEnv.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleEnv = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="envAdd">新 增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="envEdit">编 辑</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { queryApiEnvList, addApiEnv, editApiEnv, deleteApiEnv } from '@/services/apiEnv.js'
import { queryProjectAll } from '@/services/project.js'
export default {
  name: 'ApiEnv',
  data () {
    return {
      isAdd: true,
      tableDataEnv: [],
      pageInfo: {
        currentPage: 1,
        size: 10,
        total_count: null
      },
      formLabelWidth: '120px',
      dialogVisibleEnv: false,
      ruleFormEnv: {
        env_name: '',
        protocal: '',
        api_host: '',
        description: '',
        project_id: '',
        status: '1'
      },
      apiEnvFormRules: {
        env_name: [
          { required: true, message: '请输环境名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3到 20 个字符', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入环境描述', trigger: 'blur' },
          { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
        ]
      },
      valueProject: '',
      optionsProject: [],
      optionsProtocal: [
        {
          value: 'http',
          label: 'http'
        }, {
          value: 'https',
          label: 'https'
        }
      ]
    }
  },
  created () {
    this.loadApiEnvList()
  },
  methods: {
    async loadApiEnvList () {
      const queryObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await queryApiEnvList(queryObj)
      if (res.status === 1) {
        this.tableDataEnv = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    async loadApiProjectAll () {
      const queryObj = { query_type: 'query_all' }
      const { data: res } = await queryProjectAll(queryObj)
      if (res.status === 1) {
        this.optionsProject = res.data
      }
    },
    // 环境信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadApiEnvList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadApiEnvList()
      console.log(`当前页: ${val}`)
    },
    handleAdd () {
      this.isAdd = true
      this.ruleFormEnv = {
        env_name: '',
        protocal: '',
        api_host: '',
        description: '',
        project_id: '',
        status: '1'
      }
      this.dialogVisibleEnv = true
      this.loadApiProjectAll()
    },
    async handleEdit (rowData) {
      this.isAdd = false
      this.ruleFormEnv = rowData
      this.dialogVisibleEnv = true
      this.loadApiProjectAll()
      this.valueProject = rowData.id
    },
    handleDel (rowData) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const deleteObj = { id: rowData.id }
        const { data: res } = await deleteApiEnv(deleteObj)
        if (res.status === 1) {
          this.$message.success('删除环境信息成功')
          this.loadApiEnvList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async envAdd () {
      const addEnvObj = this.ruleFormEnv
      const { data: res } = await addApiEnv(addEnvObj)
      if (res.status === 1) {
        this.dialogVisibleEnv = false
        this.$message.success('添加环境成功')
        this.loadApiEnvList()
      }
    },
    async envEdit () {
      const editEnvObj = this.ruleFormEnv
      const { data: res } = await editApiEnv(editEnvObj)
      if (res.status === 1) {
        this.dialogVisibleEnv = false
        this.$message.success('编辑环境成功')
        this.loadApiEnvList()
      }
    },
    closeDialogHandle () {
      this.$refs.ruleFormEnvRef.resetFields()
      this.loadApiEnvList()
    }
  }
}
</script>

<style>

</style>
